import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {fetchSendRepeatConfirmedEmailMaster} from "../../redux/actions/confirmed_email";

const ConfirmedEmail = () => {
    const dispatch = useDispatch();

    const {isSend} = useSelector(({confirmed_email}) => confirmed_email);
    const {masterInfo} = useSelector(({master}) => master);

    const sendRepeatEmail = () => {
        dispatch(fetchSendRepeatConfirmedEmailMaster());
    };

    return (
        <section className="confirmed-email">
            <div className="container">
                <div className="confirmed-email-wrapper">
                    <div className="confirmed-email-text">
                        <h2 className="title__mb confirmed-email-text__title">
                            Подтвердите email
                        </h2>
                        <p className="description__mb confirmed-email-text__description">
                            На ваш email было отправлено письмо с ссылкой на
                            подтверждение аккаунта. Если письмо не пришло
                            проверьте папку «спам»{" "}
                        </p>
                        <button
                            className={`btn ${
                                isSend ? "disabled" : ""
                            } confirmed-email-text__btn`}
                            onClick={sendRepeatEmail}
                            disabled={isSend}
                        >
                            {isSend
                                ? `Письмо отправлено на ${masterInfo.email}`
                                : `Отправить письмо еще раз`}
                        </button>
                    </div>
                    <div className="confirmed-email-image">
                        <svg
                            viewBox="0 0 1638 1386"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M336.597 793.932C360.096 827.286 402.483 845.875 436.997 865.45C518.698 911.788 594.131 968.037 671 1021.85C702.696 1044.03 751.645 1070.18 772.775 1104.76C779.383 1115.57 781.886 1097.55 786.529 1087.86C803.356 1052.76 813.248 1015.17 830.736 980.192C875.671 890.323 934.647 808.384 991.65 725.951C1079.85 598.403 1165.27 466.614 1263.38 346.358C1274.94 332.184 1294.96 300.602 1312.69 291.738"
                                stroke="#DFAB7D"
                                strokeWidth="7"
                                strokeLinecap="round"
                            />
                            <path
                                d="M336.597 790.396C430.785 863.653 524.965 936.593 622.274 1005.73C652.173 1026.98 677.96 1053.95 709.117 1073.13C721.079 1080.49 733.151 1085.74 744.876 1092.77C756.134 1099.53 778.04 1104.36 785.743 1113.99C787.632 1116.36 796.903 1082.8 797.924 1078.63C803.477 1055.91 811.993 1035.5 819.144 1013.4C837.911 955.391 869.667 902.795 895.377 847.767C914.606 806.61 939.314 769.182 962.572 730.274C989.963 684.451 1015.34 639.25 1046.66 595.884C1076.15 555.054 1106.22 515.524 1139.4 477.605C1174.04 438.013 1216.51 407.451 1256.11 373.079C1274.72 356.927 1299.5 331.047 1322.52 320.816C1328.85 318.001 1331.7 316.056 1333.91 309.421"
                                stroke="#DFAB7D"
                                strokeWidth="7"
                                strokeLinecap="round"
                            />
                            <path
                                d="M76.5 343.5C76.5 434.987 97.1331 525.322 98.7069 616.6C100.122 698.67 99.3171 790.02 112.517 871.107C128.693 970.472 151.976 1076.73 151.976 1177.9C151.976 1200.81 122.466 1299.11 141 1315C157.698 1329.31 164.345 1335.3 174.664 1351.51C187.193 1371.2 201.111 1372.52 223.987 1373.22C305.261 1375.7 386.288 1381.67 467.643 1382.09C726.916 1383.43 976.431 1367.69 1234.12 1342.64C1296.97 1336.52 1360.97 1344.09 1422.04 1328.82C1456.88 1320.11 1484.84 1311.07 1521.18 1311.07C1569.46 1311.07 1637.45 1318.25 1634.13 1260.27C1627.84 1150.23 1599.11 1046.05 1599.11 934.241C1599.11 840.322 1590.23 747.143 1590.23 653.099C1590.23 537.709 1599.76 417.844 1588.26 302.906C1582.18 242.121 1562.8 182.453 1554.72 121.891C1547.45 67.3866 1553.68 55.202 1498.99 44.9467C1428.99 31.8225 1364.37 23.7378 1293.31 23.7378C1016.44 23.7378 739.577 23.7378 462.711 23.7378C328.223 23.7378 193.736 23.7378 59.2485 23.7378C21.5281 23.7378 36.0727 27.1985 53.8229 50.8654C63.5002 63.7685 72.0725 95.8557 72.0725 112.519C72.0725 178.12 76.5 296.5 76.5396 350.5"
                                stroke="#DFAB7D"
                                strokeWidth="7"
                                strokeLinecap="round"
                            />
                            <path
                                d="M44.5 323.5C44.5 414.987 65.1331 505.322 66.7069 596.6C68.1219 678.67 67.3171 770.02 80.5173 851.107C96.693 950.472 119.976 1056.73 119.976 1157.9C119.976 1180.81 90.4656 1279.11 109 1295C125.698 1309.31 132.345 1315.3 142.664 1331.51C155.193 1351.2 169.111 1352.52 191.987 1353.22C273.261 1355.7 354.288 1361.67 435.643 1362.09C694.916 1363.43 944.431 1347.69 1202.12 1322.64C1264.97 1316.52 1328.97 1324.09 1390.04 1308.82C1424.88 1300.11 1452.84 1291.07 1489.18 1291.07C1537.46 1291.07 1605.45 1298.25 1602.13 1240.27C1595.84 1130.23 1567.11 1026.05 1567.11 914.241C1567.11 820.322 1558.23 727.143 1558.23 633.099C1558.23 517.709 1567.76 397.844 1556.26 282.906C1550.18 222.121 1530.8 162.453 1522.72 101.891C1515.45 47.3866 1521.68 35.202 1466.99 24.9467C1396.99 11.8225 1332.37 3.73779 1261.31 3.73779C984.444 3.73779 707.577 3.73779 430.711 3.73779C296.223 3.73779 161.736 3.73779 27.2485 3.73779C-10.4719 3.73779 4.07269 7.19846 21.8229 30.8654C31.5002 43.7685 40.0725 75.8557 40.0725 92.5192C40.0725 158.12 44.5 276.5 44.5396 330.5"
                                stroke="#DFAB7D"
                                strokeWidth="7"
                                strokeLinecap="round"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ConfirmedEmail;
