import React from "react";

const Dzen: React.FC = () => {
    return (
        <svg
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M59.6429 59.6429C51.7857 67.75 51.2143 77.8571 50.5357 100C71.1786 100 85.4286 99.9286 92.7857 92.7857C99.9286 85.4286 100 70.5 100 50.5357C77.8571 51.25 67.75 51.7857 59.6429 59.6429ZM0 50.5357C0 70.5 0.0714285 85.4286 7.21429 92.7857C14.5714 99.9286 28.8214 100 49.4643 100C48.75 77.8571 48.2143 67.75 40.3571 59.6429C32.25 51.7857 22.1429 51.2143 0 50.5357ZM49.4643 0C28.8571 0 14.5714 0.0714285 7.21429 7.21429C0.0714285 14.5714 0 29.5 0 49.4643C22.1429 48.75 32.25 48.2143 40.3571 40.3571C48.2143 32.25 48.7857 22.1429 49.4643 0ZM59.6429 40.3571C51.7857 32.25 51.2143 22.1429 50.5357 0C71.1786 0 85.4286 0.0714285 92.7857 7.21429C99.9286 14.5714 100 29.5 100 49.4643C77.8571 48.75 67.75 48.2143 59.6429 40.3571Z"
                fill="#DD9E5E"
            />
        </svg>
    );
};

export default Dzen;
