import React from "react";

const DraftsNull = () => {
    return (
        <div className="drafts-null">
			<h3 className="drafts-null__title">
				У вас нету черновиков
			</h3>
        </div>
    );
};

export default DraftsNull;
