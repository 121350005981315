import React from "react";

const Vk: React.FC = () => {
    return (
        <svg
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="5"
                y="5"
                width="90"
                height="90"
                rx="20"
                stroke="#DD9E5E"
                strokeWidth="10"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.02083 7.02083C0 14.0625 0 25.3542 0 48V52C0 74.625 0 85.9583 7.02083 92.9792C14.0625 100 25.3542 100 48 100H52C74.625 100 85.9583 100 92.9792 92.9792C100 85.9375 100 74.6458 100 52V48C100 25.375 100 14.0417 92.9792 7.02083C85.9375 0 74.6458 0 52 0H48C25.375 0 14.0417 0 7.02083 7.02083ZM16.8958 30.5625C17.4167 56.5625 31.1042 72.2292 53.6667 72.2292H54.9792V57.3333C63.2083 58.1667 69.3333 64.3125 71.8333 72.2292H83.6875C82.5182 67.7687 80.4247 63.6037 77.5428 60.004C74.6609 56.4043 71.0549 53.4503 66.9583 51.3333C72.125 48.2292 79.4583 40.7292 81.1875 30.5625H70.3958C68.1458 38.8333 61.4167 46.3333 54.9792 47.0417V30.5625H44.0208V59.4167C37.3542 57.75 28.6667 49.6667 28.3125 30.5625H16.8958Z"
                fill="#DD9E5E"
            />
        </svg>
    );
};

export default Vk;
