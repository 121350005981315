import React from "react";
import { Helmet } from "react-helmet";
import {useParams} from "react-router-dom";

import {
    CourseRegulationsMenu,
    CourseRegulationsContentItem,
} from "../components/";

const CourseRegulations = () => {
	const {blockId} = useParams();

    React.useEffect(() => {
        if (blockId) {
            const element = document.getElementById(blockId);

            if (element) {
                window.scrollTo(0, element.getBoundingClientRect().top - 200);
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [blockId]);

    const regulationsBlocks = [
        {
            title: "С чего начать?",
            items: [
                {
                    title: "Выберите тему курса",
                    id: "theme-course",
                    description:
                        "Несколько советов, которые помогут определиться с темой курса",
                    blocksDescriptions: [
                        {
                            subtitle: "Учите тому, что знаете",
                            description:
                                "Пусть ваш курс будет сосредоточен на конкретном изделии, навыке или предмете, которым владеете в совершенстве и о котором есть что рассказать.",
                        },
                        {
                            subtitle: "Поставьте себя на место ученика",
                            description:
                                "Спросите: «Чему бы я хотел научиться, когда только начинал разбираться в этой сфере, каких знаний мне не хватало?». Это поможет более узко подобрать тему курса и проработать его сценарий.",
                        },
                        {
                            subtitle: "Слушайте людей вокруг",
                            description:
                                "Обращайте внимание на то, как реагирует окружение на ваши работы, какие вопросы задают, хотят ли купить, научиться делать также, просят совет. Эти вопросы могут указать на интересную и востребованную тему для курса.",
                        },
                        {
                            subtitle: "Изучите список недопустимых тем HobJob",
                            description:
                                "Наша основная цель - творчество. Список допустимых тем - справочник, в котором рассказано, о чем нельзя преподавать на HobJob.",
                            hrefTitle: "Недопустимые темы",
                            hrefId: "invalid-themes",
                        },
                    ],
                },
                {
                    title: "Дайте название",
                    id: "give-name",
                    blocksDescriptions: [
                        {
                            description:
                                "Название - не менее важная часть в создании курса. О нем можно начать думать уже на этапе планирования сценария. Из названия должно быть сразу понятно, чему человек научится.",
                        },
                        {
                            subtitle: "Например",
                            description: "«Основы каллиграфии»",
                        },
                    ],
                },
                {
                    title: "Составьте описание",
                    id: "write-description",
                    description: "Ответьте на вопросы:",
                    blocksDescriptions: [
                        {
                            subtitle: "О чем курс?",
                            description:
                                "Опишите в 2-3 предложениях, чему будете учить на курсе. Это первое, что увидит человек, поэтому пишите четко и ясно, чтобы сразу была понятна тема и конечный результат.",
                        },
                        {
                            subtitle: "Чему научитесь/узнаете на курсе?",
                            description:
                                "Перечислите навыки и знания, которые человек получит и сможет применить после прохождения курса. ",
                        },
                        {
                            subtitle: "Кому подойдет этот курс?",
                            description:
                                "Расскажите на кого рассчитан курс, для широкой или узкой аудитории, например, на дизайнеров, художников и т. д. Также упомяните, нужны ли какие-то предварительные знания для прохождения и усвоения материала.",
                        },
                        {
                            subtitle: "Что понадобится для курса?",
                            description:
                                "Перечислите все необходимые материалы для прохождения курса.",
                        },
                        {
                            subtitle: "Например",
                            description:
                                "Погрузитесь в изящное искусство каллиграфии и изучите один из самых красивых стилей написания «Копперплейт»",
                        },
                    ],
                },
                {
                    title: "Cнимите курс",
                    id: "take-course",
                    blocksDescriptions: [
                        {
                            description:
                                "К записи курса можно приступить после написания сценария. Разбейте весь материал на уроки по 10 минут, так легче получать и запоминать информацию. Не торопитесь снимать все уроки за один день, работайте на качество, в комфортном для себя темпе. Успех курса зависит от подачи материала в том числе. Об оборудовании для съёмки, локациях, одежде и других нюансах читайте в разделе «Съемка и редактирование».",
                        },
                    ],
                },
                {
                    title: "Загрузите на платформу",
                    id: "upload-platform",
                    blocksDescriptions: [
                        {
                            description: "Готовый курс загрузите на платформу.",
                        },
                    ],
                },
            ],
        },

        {
            title: "Стандарты качества HobJob",
            items: [
                {
                    title: "Руководство по качеству курса",
                    id: "course-quality-guide",
                    description:
                        "Наша команда проверяет каждый курс загруженный на платформу. Чтобы модерация прошла успешно, придерживайтесь этих рекомендаций:",
                    blocksDescriptions: [
                        {
                            subtitle: "Снимайте на допустимые темы",
                            description: `Курсы должны быть нацелены на создание проекта в любом из направлений HobJob: фотография и видео, иллюстрация, 3D и анимация, дизайн, каллиграфия и типографика, ремесло и технология.`,
                            hrefTitle: "Недопустимые темы",
                            hrefId: "invalid-themes",
                        },
                        {
                            subtitle: "Вступительное видео к курсу",
                            description:
                                "Четко объясните о чем будет курс, кому подойдёт, чему человек научится, добавьте пару слов о себе. Это первое, что увидит ученик.",
                        },
                        {
                            subtitle: "Четкое название",
                            description:
                                "Каждый из видеоуроков должен иметь четкое название, отражающее его содержание. Курс и видеоуроки могут быть загружены только один раз.",
                        },
                        {
                            subtitle: "Стандартны качества аудио и видео",
                            description: `
								<ul>
									<li>- Минимальная длина урока - 10 минут</li>
									<li>- Максимальная длина курса - 120 минут</li>
									<li>- Звук должен синхронизироваться с видео, быть четким и без лишних шумов</li>
									<li>- Видео должно быть снято статичной камерой, с хорошим светом, кадрированием и в высоком разрешении - 1080p</li>
									<li>- Скриншоты, изображения и текст также должны быть в высоком разрешении</li>
									<li>- На видео не должно быть никаких водяных знаков</li>
									<li>- Фоновая музыка на уроке должна быть без авторских прав и не перебивать голос</li>
								</ul>
							`,
                        },
                        {
                            subtitle: "Стиль курса",
                            description: `
								<ul>
									<li>- Локация для уроков должна соответствовать теме курса</li>
									<li>- Используйте один выбранный шрифт на всех уроках</li>
									<li>- Анимации и картинки должны быть в одной цветовой гамме</li>
								
								</ul>
							`,
                        },
                        {
                            subtitle: "Прочее",
                            description: `
								<ul>
									<li>- Курс и видеоуроки могут быть загружены только один раз</li>
									<li>- Курс не должен обещать заработок, карьерный рост и быстрый результат</li>
									<li>- Реклама личного бренда разрешена только во вступительном и заключительном видео. При этом можно ссылаться на другие свои курсы на платформе в любом из уроков</li>
									<li>- Можно делиться сторонними сервисами, которые помогут в прохождении курса. Например, ссылаться на поставщиков, рекомендовать сайты для поиска идей и тд.</li>
								
								</ul>
							`,
                        },
                    ],
                },

                {
                    title: "Недопустимые темы",
                    id: "invalid-themes",
                    blocksDescriptions: [
                        {
                            subtitle: "",
                            description: `<ul>
									<li>- Бизнес</li>
									<li>- Маркетинг</li>
									<li>- Быстрый заработок</li>
									<li>- Пассивный доход</li>
									<li>- Отношения</li>
									<li>- Преподавание на других образовательных платформах</li>
									<li>- Медицина</li>
									<li>- Наука</li>
									<li>- Религия</li>
									<li>- Политика</li>
									<li>- Спорт</li>
									<li>- Домашние животные</li>
									<li>- Косметология</li>
									<li>- Астрология</li>
									<li>- Психология</li>
									<li>- Магия</li>
									<li>- Образ жизни</li>
									<li>- Программирование</li>
									<li>- Мода</li>
								</ul>`,
                        },
                    ],
                },

                {
                    title: "Дополнительные правила для мастера",
                    id: "additional-rules-master",
                    blocksDescriptions: [
                        {
                            subtitle: "",
                            description: `<ul>
									<li>- Можно загружать максимум 1 курс в неделю</li>
									<li>- Необходимо соблюдать политику платформы</li>
									<li>- Вы не должны нарушать авторские права. Если у вас есть разрешение на использование музыки или картинок, смело пользуйтесь</li>
									<li>- Нельзя самостоятельно просить дополнительную оплату за курс на и вне платформы</li>
									<li>- Нельзя рекомендовать другие образовательные платформы с вашим курсом</li>
									<li>- Нельзя говорить, что ваш курс создан платформой HobJob</li>
									<li>- Нельзя публиковать чужие бесплатные материалы</li>
									<li>- Нельзя публиковать свои уже доступные бесплатные материалы</li>
								</ul>`,
                        },
                    ],
                },
            ],
        },

        {
            title: "Съемка и редактирование",
            items: [
                {
                    title: "Выберите качественное оборудование",
                    id: "choose-quality-equipment",
                    blocksDescriptions: [
                        {
                            subtitle: "",
                            description:
                                "Не обязательно покупать дорогую камеру для съёмки курса. Даже в современных смартфонах зачастую уже есть хорошая встроенная камера. Главный критерий - широкоэкранное видео 16:9 горизонтально с разрешением 1080p или выше и хорошая стабилизация.",
                        },
                        {
                            subtitle: "",
                            description:
                                "Более важную роль играет звук. Его нужно записывать отдельно, впоследствии накладывая на видео. Петличный микрофон - отличное решение. Они бывают разной цены, цвета и формы. Такой микрофон легко использовать, он хорошо подавляет лишние шумы и маскируется одеждой. Не рекомендуем использовать наушники от смартфонов, например, Airpods. Качества звука оставляет желать лучшего, плюс они слишком привлекают внимание на видео. Если же у вас есть профессиональная камера или микрофон - пользуйтесь ими, мы только за.",
                        },
                    ],
                },

                {
                    title: "Подготовьте локацию для съемки",
                    id: "prepare-location-shooting",
                    blocksDescriptions: [
                        {
                            subtitle: "",
                            description:
                                "Хорошее освещение больше всего влияет на качество видео. Необязательно покупать дорогое оборудование, можно воспользоваться естественным освещением, снимать возле окна. Поэкспериментируйте с ракурсом, посмотрите как меняется картинка, в зависимости от того, как падает свет. Снимайте рядом с окном, а не перед ним. Если в помещении нет окон или вы снимаете в пасмурный день, используйте искусственное освещение.",
                        },
                        {
                            subtitle: "",
                            description:
                                "Подберите локацию соответствующую теме вашего курса. Уберите все лишние предметы из кадра: вещи, провода, документы и тд. Окружите себя реквизитом, добавьте в кадр предметы, которые понадобятся для курса: краски, ткань, посуда, цветы и тд, создайте уютное творческое пространство. Выберите удобную одежду, отражающую вас, но не отвлекающую на себя все внимание.",
                        },
                    ],
                },

                {
                    title: "Проработайте сценарий",
                    description:
                        "Чтобы съёмка курса прошла легче, воспользуйтесь этими советами:",
                    id: "work-script",
                    blocksDescriptions: [
                        {
                            subtitle: "",
                            description: `<ul>
									<li>- Заранее составьте текст к каждому уроку и несколько раз прогоните его, расскажите друзьям, родственникам или себе перед зеркалом. Не нужно зубрить, но порепетировать стоит</li>
									<li>- За день-два до съёмки подготовьте оборудование, материалы, реквизит. Проверьте камеру, микрофон, свет</li>
									<li>- Продумайте какие фотографии нужно сделать для стоп-кадров, заставков к урокам. Запишите, чтобы не забыть</li>
									<li>- Подготовьте локацию к съёмке. Уберитесь в мастерской или дома, выберите место, где будете снимать, принесите нужный реквизит. Забронируйте студию или другое место, если нет возможности снимать в мастерской или дома</li>
									<li>- Мы рекомендуем использовать при съёмке различные визуальные эффекты: смену кадра, крупный план, вставки с фото/видео, текст и тд. Продумайте заранее, как бы вы хотели снимать, найдите шрифт, картинки, фоны, видео. Подберите одну цветовую палитру, проверьте разрешение и размеры картинок (1920 x 1080p, соотношение сторон 16:9)</li>
									<li>- Найдите музыку, которая задаст настроение вашему курсу. В интернете есть много ресурсов, где можно бесплатно найти музыку, аудиоэффекты или картинки без авторского права</li>
									<li>- В день съемок выделите себе минимум 3-4 часа для работы. Предупредите, что снимаете курс, попросите, чтобы не беспокоили по пустякам. Всё проще, чем кажется, у вас точно получится</li>
								</ul>`,
                        },
                    ],
                },

                {
                    title: "Снимите и смонтируйте курс",
                    id: "film-mount-course",
                    blocksDescriptions: [
                        {
                            subtitle: "Общие советы по съёмке:",
                            description: `<ul>
									<li>- Приготовьтесь делать несколько дублей. Используйте хлопушку или просто хлопки (что-то визуально и звуково сигнализирующее) каждый раз, когда начинаете записывать новую часть. Озвучивайте номер урока и дубля. Это упростит организацию отснятого материала при постобработке</li>
									<li>- Снимайте небольшими фрагментами. Каждый урок - новое видео, которое тоже можно при необходимости разделить на несколько частей. Так будет удобнее находить нужное и монтировать</li>
									<li>- Снимайте первый урок в последнюю очередь. Он всегда бесплатно будет доступен для просмотра, с него человек начнёт знакомство с вашим курсом. В первом уроке вы представляетесь, рассказываете пару слов о себе и о курсе, делитесь необходимыми материалами для прохождения, рассказываете и показываете, чему человек научится.  Съемка первого урока после того, как вы отсняли весь остальной материал даёт возможность лучше понять, что говорить, как сидеть и тд. Плюс вы привыкаете к камере, меньше запинаетесь, чувствуете себя расслабленнее и увереннее</li>
									<li>- Расположите камеру на уровне глаз, в 90-120 сантиметрах от вас. Не бойтесь жестикулировать, указывать на предметы. Если снимаете крупный план сверху, например, как что-то пишете, рисуете, лепите и тд, рекомендуем снимать в формате 4к, так как это снижает вероятность пикселизации при увеличении</li>
								</ul>`,
                        },
                        {
                            subtitle: "Запись аудио",
                            description:
                                "Мы рекомендуем записывать звук отдельно, используя, например, петличный микрофон, чтобы убрать лишние шумы и общую размытость.",
                        },
                        {
                            subtitle: "Советы для записи аудио",
                            description: `<ul>
									<li>- Располагайте микрофон на расстоянии 15-30 сантиметров от рта. Если используете петличный микрофон, убедитесь, что он не касается волос, одежды или украшений</li>
									<li>- Всегда выполняйте небольшой тест записи звука перед началом съёмки, чтобы проверить всё ли работает. Послушайте запись, в идеале в наушниках, и прислушайтесь к посторонним шумам, шороху и гудению, если ничего такого нет, спокойно начинайте съёмку</li>
									<li>- Соблюдайте одно и то же расположение микрофона и настройки, чтобы звук всегда звучал одинаково</li>
									<li>- Вы можете записывать озвучку курса отдельно от съёмки. Это можно делать в студии, дома или где-либо ещё. Но не забывайте про качество: никаких шумов, гула, треска и тд.</li>
								</ul>`,
                        },
                        {
                            subtitle: "Монтаж видео",
                            description:
                                "Монтировать видео совсем не сложно. Главная задача убрать лишнее (запинания, вздохи, смешки, паузы) и добавить визуальные эффекты (музыка, картинки, текст и тд).",
                        },
                        {
                            subtitle: "Советы для монтажа видео",
                            description: `<ul>
									<li>- Ознакомьтесь с программами для монтажа. Большинство из них работает примерно одинаково. В интернете можно найти бесплатные обучающие ролики или статьи. Для новичков хорошо подойдет, например, Movavi Видеоредактор или Sony Vegas Pro</li>
									<li>- Создайте отдельные папки для отредактированного материала и для исходного. Также создайте папки с музыкой, звуком, картинками и тд.</li>
									<li>- Делайте длительность урока не более 10 минут, так легче воспринимается материал. Спокойно разделяйте длинное видео на несколько уроков</li>
									<li>- Не выдумывайте. Не нужно стараться сделать супер-крутой монтаж, человек пришел к вам на курс не за этим. Используйте простые переходы, нам не нужны свадебные клипы из 2000-х</li>
									<li>- Меняйте ракурс, кадр или вставляйте фото каждые 30-45 секунд в основных уроках или каждые 3-5 секунд в первом уроке. Создайте динамику, акцентируйте внимание на важном моменте с помощью крупного плана. Это сделает человека более вовлеченным в процесс</li>
									<li>- Вставляйте слайды или дополнительный текст на экране там, где это необходимо, чтобы упорядочить содержание урока, выделить важное или разбить сложные приемы</li>
									<li>- Используйте таймлапс. Чтобы подолгу не смотреть, как выполняется одно и то же действие, ускоряйте видео. Можно наложить закадровый голос, где вы объясняете то или иное действие, даете совет. Так смотреть урок будет интереснее</li>
									<li>- Для редактирования звука можно использовать программу Adobe Audition, как ей пользоваться смотрите в интернете</li>
									<li>- Занимайтесь звуком в хороших наушниках, так лучше будет слышно его качество и динамику.  Музыка не должна отвлекать от голоса</li>
								</ul>`,
                        },
                    ],
                },

                {
                    title: "Настройки для записи и экспорта видео",
                    id: "settings-recording-exporting-videos",
                    blocksDescriptions: [
                        {
                            subtitle: "Настройки видео",
                            description: `<ul>
									<li>- Видео должно быть записано в формате 1080p. Также рекомендуем использовать формат 4к, это даст вам достаточно пикселей для экспериментов, позволит создавать  проводки, приближения и многое другое. Почитайте статьи в интернете о том, как пользоваться этими возможностями</li>
									<li>- Соотношение сторон 16:9 по горизонтали</li>
									<li>- Частота кадров: 24 или 30 fps</li>
									<li>- Видео должно содержать звуковую дорожку</li>
								</ul>`,
                        },

                        {
                            subtitle: "Экспорт",
                            description: `<ul>
									<li>- Размер видео не может превышать 2 ГБ</li>
									<li>- Доступные форматы видео: .mp4, .avi, .mov, .mpeg, .webm</li>
									<li>- Доступные форматы фото: .jpg, .jpeg, .png</li>
									<li>- Максимальный вес материалов 5мб.</li>
								</ul>`,
                        },

                        {
                            subtitle: "",
                            description: `Если возникли проблемы с загрузкой курса, пишите на почту <a href="mailto:support@hobjob.ru">support@hobjob.ru</a>`,
                        },
                    ],
                },
            ],
        },

        {
            title: "Реклама курса",
            items: [
                {
                    title: "Рекомендации по социальным сетям",
                    id: "social-media-recommendations",
                    blocksDescriptions: [
                        {
                            subtitle: "",
                            description:
                                "Делитесь своим курсом в социальных сетях. Можно начать о нем рассказывать еще на этапе съёмки, показывать процесс, выкладывать посты с фотографиями, которые будете использовать и в курсе. Можно рассказывать про нашу платформу, отмечать официальные аккаунты HobJob, мы поддерживаем мастеров, делимся их постами, анонсируем выход каждого курса.",
                        },
                    ],
                },

                {
                    title: "Будет ли HobJob продвигать курсы?",
                    id: "will-HobJob-promote-course",
                    blocksDescriptions: [
                        {
                            subtitle: "",
                            description:
                                "Да, у нас несколько каналов продвижения, начиная от e-mail рассылки, заканчивая таргетингом в социальных сетях. Курсы, которые большего всего просматривают люди, продвигаются самой платформой, попадая в раздел “Рекомендовано HobJob”. Также отдельное внимание уделяется новым курсам на платформе. Чем больше людей посмотрело ваш курс, тем выше он будет в результатах поиска.",
                        },
                    ],
                },
            ],
        },

        {
            title: "Выплаты",
            items: [
                {
                    title: "От чего зависит моя прибыль?",
                    id: "what-does-profit-depend-on",
                    blocksDescriptions: [
                        {
                            subtitle: "",
                            description:
                                "HobJob работает по системе подписки. Каждый месяц 30% от общей прибыли отправляется на выплаты мастерам. Мастер получает оплату из этого фонда за минуты просмотра своего курса. Например, если минуты просмотра ваших курсов составляют 5% от общего числа просмотренных минут всех курсов на платформе, то вы получаете 5% от зарплатного фонда.",
                        },
                    ],
                },

                {
                    title: "Где я могу найти информацию о статике просмотров курса?",
                    id: "statistics-info-master",
                    blocksDescriptions: [
                        {
                            subtitle: "",
                            description:
                                "В вашем личном кабинете мастера HobJob.",
                        },
                    ],
                },

                {
                    title: "Когда и как я буду получать платежи от HobJob?",
                    description: "HobJob проводит выплату мастерам в рублях. ",
                    id: "when-how-will-receive-payments-HobJob?",
                    blocksDescriptions: [
                        {
                            subtitle: "",
                            description: `Платежи за каждый месяц выплачиваются 15 числа следующего месяца. То есть вы будете получать оплату за минуты просмотра ваших курсов и рефералов с 1 по 31 июля 15 августа. Время, необходимое для поступления средств на ваш счет, зависит от выбранного вами метода выплаты. Ниже приведены оценки того, когда вы можете ожидать получения платежа после его выплаты HobJob`,
                        },
                        {
                            subtitle: "Варианты оплаты, которые мы предлагаем:",
                            description: `<ul>
									<li>Выплаты на карту: в течение суток</li>
									<li>Выплаты на расчетный счет: от 3-х до 4-х рабочих дней</li>
								</ul>`,
                        },
                    ],
                },

                {
                    title: "Могу ли я сделать скидку на свой курс?",
                    id: "can-make-discount-course",
                    blocksDescriptions: [
                        {
                            subtitle: "",
                            description:
                                "Вы не можете делать скидки на свои курсы. Но первый урок всегда предоставляется человеку бесплатно, можете предлагать его.",
                        },
                    ],
                },

                {
                    title: "Могу ли я предоставить бесплатный доступ к курсу?",
                    id: "can-provide-free-access-course?",
                    blocksDescriptions: [
                        {
                            subtitle: "",
                            description:
                                "Нет. Но каждому человеку даётся неделя бесплатной пробной подписки, чтобы он мог познакомиться с мастерами и платформой. Рассказывая о курсе, можете упоминать про бесплатную неделю.",
                        },
                    ],
                },

                {
                    title: "Как пользоваться реферальной программой?",
                    id: "how-to-use-the-referral-program?",
                    blocksDescriptions: [
                        {
                            subtitle: "",
                            description:
                                "Поделитесь своей уникальной ссылкой в соц.сетях, чтобы 400 рублей за каждого нового пользователя на платформе, как только он оплатит подписку. Ссылку можно найти в личном кабинете.",
                        },
                    ],
                },

                {
                    title: "Нужно ли мне платить налог с доходов от HobJob?",
                    id: "do-need-pay-tax-on-HobJob-income?",
                    blocksDescriptions: [
                        {
                            subtitle: "",
                            description:
                                "Да, платежи, которые вы получаете от HobJob считаются доходом и облагаются налогом согласно закону РФ.",
                        },
                    ],
                },
            ],
        },
    ];

    return (
        <>
            <Helmet>
                <title>Рекомендации для мастеров - HobJob для мастеров</title>
            </Helmet>

            <section className="course-regulations">
                <div className="container">
                    <div className="course-regulations-wrapper">
                        <CourseRegulationsMenu
                            regulationsMenu={regulationsBlocks}
                        />

                        <div className="course-regulations-content-wrapper">
                            {regulationsBlocks.map((item, index) => (
                                <CourseRegulationsContentItem
                                    {...item}
                                    mainIndex={index}
                                    key={`course-regulations-content-item-${index}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CourseRegulations;
