import React from "react";

const Youtube: React.FC = () => {
    return (
        <svg
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="5"
                y="5"
                width="90"
                height="90"
                rx="20"
                stroke="#DD9E5E"
                strokeWidth="10"
            />
            <path
                d="M18.9186 6.04675C17.2677 6.29557 15.9065 6.65496 14.7474 7.14338C9.78588 9.23526 6.30843 13.6586 5.21075 19.2616V19.2616C5.07059 19.9664 5 20.6833 5 21.4019V50.4555V79.529C5 80.2345 5.06763 80.9385 5.20197 81.6311V81.6311C5.50054 83.1701 5.93083 84.4786 6.62456 85.9347C7.32708 87.4091 8.05594 88.5242 9.09215 89.6945C11.4104 92.3209 14.4049 94.0626 17.8033 94.7538V94.7538C18.4573 94.8886 19.1233 94.9569 19.791 94.9577L48.7315 94.9934C70.325 95.0118 78.9835 94.9934 79.7739 94.9196C82.3556 94.68 84.709 93.823 86.8253 92.3762C90.1886 90.0724 92.4981 86.7272 93.508 82.7001C94.0261 80.599 93.9998 82.6724 93.9998 50.0132C93.9998 20.137 93.9998 20.0725 93.8154 19.1418C93.5344 17.695 93.0689 16.2942 92.4103 14.9304C90.6101 11.2258 87.7474 8.52568 84.0416 7.03279C83.0845 6.65496 81.416 6.22184 80.3886 6.08361C79.7036 6.00067 19.4981 5.96381 18.9186 6.04675ZM57.2495 28.4309C59.6205 28.4861 65.3548 28.7165 66.1187 28.7995C66.3646 28.8179 66.9179 28.864 67.3481 28.8916C70.5797 29.1036 72.977 29.5643 74.1977 30.2002C75.9627 31.1309 77.4117 32.9464 78.0088 34.9738C79.7124 40.7518 79.7124 60.0672 78.0088 65.8452C77.4117 67.8726 75.9627 69.688 74.1977 70.6187C72.9683 71.2638 70.527 71.7246 67.3481 71.9273C66.8652 71.955 66.3119 72.001 66.1187 72.0195C65.6885 72.0748 60.1474 72.3144 57.469 72.3973C54.9136 72.471 44.0422 72.471 41.4868 72.3973C38.8085 72.3144 33.2674 72.0748 32.8371 72.0195C32.6439 72.001 32.0907 71.955 31.6077 71.9273C28.4288 71.7246 25.9876 71.2638 24.7582 70.6187C22.9931 69.688 21.5442 67.8726 20.9471 65.8452C19.2435 60.0672 19.2435 40.7518 20.9471 34.9738C21.5442 32.9464 22.9844 31.1402 24.7582 30.2094C25.97 29.5643 28.3498 29.1128 31.6077 28.8916C32.0468 28.864 32.6176 28.8179 32.881 28.7902C34.2861 28.6612 39.7745 28.4677 43.5505 28.4124V28.4124C46.4419 28.3787 49.3532 28.3001 52.2445 28.3433C53.7438 28.3657 55.4905 28.3963 57.2495 28.4309Z"
                fill="#DD9E5E"
            />
            <path
                d="M43.4185 50.4095C43.4185 55.4779 43.436 59.6248 43.4536 59.6248C43.4799 59.6248 46.6237 57.7541 54.4831 53.0543C56.0988 52.0867 57.7497 51.1098 58.1449 50.8794C58.5489 50.6491 58.8738 50.4371 58.8738 50.4095C58.8738 50.3818 58.3644 50.0685 57.7497 49.7091C57.135 49.3497 55.4666 48.3544 54.044 47.5066C47.3525 43.4979 43.4799 41.1941 43.4536 41.1941C43.436 41.1941 43.4185 45.341 43.4185 50.4095Z"
                fill="#DD9E5E"
            />
        </svg>
    );
};

export default Youtube;
